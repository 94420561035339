<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.smAndDown
      }"
    >
      <div v-if="!isLoading">
        <v-card class="msa-list">
          <!-- display:block for ie11 -->
          <div class="d-flex flex-column" style="display:block;">
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-col class="pt-3 pb-0">
                <v-text-field
                  id="delegates--search-field"
                  v-model="searchString"
                  aria-label="filter delegates list"
                  class="data-table--search-field"
                  hide-details
                  label=""
                  outlined
                  placeholder="Search delegates"
                  prepend-inner-icon="search"
                  @input="onSearchChangeDebounced"
                >
                  <template slot="append">
                    <v-btn
                      v-if="
                        fnActionByName(uIActions.ADD_DELEGATE) &&
                          $vuetify.breakpoint.mdAndUp
                      "
                      id="delegates--new-delegate-button"
                      aria-label="New Delegate"
                      class="primary"
                      @click="openStaffSelect"
                    >
                      <v-icon class="mr-3">
                        mdi-account-plus-outline
                      </v-icon>
                      New Delegate
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              class="data-table--header"
            >
              <v-col>
                <v-list class="pa-0">
                  <v-list-item class="pt-1 pb-1">
                    <div class="mr-3" style="width:42px" />
                    <v-list-item-content>
                      <v-container>
                        <v-row>
                          <v-col class="col-sm-4">
                            <v-list-item-title>Delegate Name</v-list-item-title>
                          </v-col>
                          <v-col class="col-sm-4">
                            <v-list-item-title>Position</v-list-item-title>
                          </v-col>
                          <v-col class="col-sm-4">
                            <v-list-item-title>Delegation</v-list-item-title>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>

                    <!-- placeholders for alignment -->
                    <v-list-item-action class="mt-0 mb-0">
                      <v-btn icon style="visibility: hidden">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action class="mt-0 mb-0">
                      <v-btn icon style="visibility: hidden">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <!-- Staff list -->
            <div>
              <div
                v-if="
                  delegates(locationId) && delegates(locationId).length === 0
                "
                class="subheading mt-6 mb-3"
              >
                No results found
              </div>
              <v-list style="background-color: inherit" two-line>
                <div
                  v-for="s in delegates(locationId)"
                  :id="`delegates--row-${s.netRegId}`"
                  :key="s.detuserid"
                >
                  <v-list-item @click="goToStaffAppDetails(s)">
                    <InitialsIcon
                      :given-name="s.firstname"
                      :surname="s.lastname"
                      class="mr-3"
                      size="42"
                    />

                    <v-list-item-content>
                      <v-container>
                        <v-row
                          :class="{
                            'flex-column': $vuetify.breakpoint.smAndDown
                          }"
                        >
                          <v-col class="col-sm-4 align-self-md-center">
                            <v-list-item-title
                              >{{ s.firstname + " " + s.lastname }}
                            </v-list-item-title>
                          </v-col>
                          <v-col class="col-sm-4 align-self-md-center">
                            <v-list-item-title
                              >{{ s.staffRoleFormatted }}
                            </v-list-item-title>
                          </v-col>
                          <v-col class="col-sm-4">
                            <div class="d-flex justify-start">
                              <Pill
                                :label="
                                  s.fullAccess === 'Y' ? 'full' : 'partial'
                                "
                                v-bind="determinePillColour(s.fullAccess)"
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        v-if="fnActionByName(uIActions.DELEGATE_APP)"
                        :id="`delegates--row-delete-button-${s.netRegId}`"
                        :aria-label="'delete ' + s.firstname + '-' + s.lastname"
                        icon
                        ripple
                        @click="deleteDelegation($event, s)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn
                        v-if="fnActionByName(uIActions.LIST_DELEGATE_APPS)"
                        :id="`delegates--row-edit-button-${s.netRegId}`"
                        :aria-label="'edit ' + s.firstname + '-' + s.lastname"
                        icon
                        ripple
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </div>
          </div>
        </v-card>
        <div class="mt-4 mb-4">
          &nbsp;
        </div>

        <v-btn
          v-if="
            $vuetify.breakpoint.smAndDown &&
              fnActionByName(uIActions.ADD_DELEGATE)
          "
          bottom
          class="primary"
          fab
          fixed
          right
          @click="openStaffSelect"
        >
          <v-icon>mdi-account-plus-outline</v-icon>
        </v-btn>
      </div>

      <!-- select staff modal/screen -->
      <!-- Flex the entire dialog so the content can fit the height of the dialog -->
      <!-- Unflex the dialog when hiding it, otherwise display:flex overrides display:none -->
      <v-dialog
        v-if="showStaffSelect"
        v-model="showStaffSelect"
        :content-class="
          'staff-select-dialog ' + (showStaffSelect ? 'd-flex' : '')
        "
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="675px"
      >
        <div
          id="add-new-delegate-dialog--container"
          v-ads-set-focus:add-delegate-staff-list--search-field
          v-ads-trap-focus
          aria-describedBy="add-new-delegate-dialog--description"
          aria-labelledby="add-new-delegate-dialog--title"
          class="d-flex flex-column pt-6 pb-6 pl-0 pr-0"
          role="dialog"
        >
          <div class="d-flex align-start pr-6 pl-6">
            <div class="mt-1 mr-4">
              <CircleIcon icon="mdi-calendar-account-outline" />
            </div>
            <div>
              <h3 id="add-new-delegate-dialog--title">
                Add a new delegate
              </h3>
              <p id="add-new-delegate-dialog--description" class="mb-0">
                Browse or search for a staff member below.
              </p>
            </div>
            <div class="d-flex" style="flex: 1; justify-content: flex-end">
              <v-btn
                aria-label="close new delegate dialog"
                icon
                left
                @click="showStaffSelect = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
          <AddDelegateStaffList :location-id="locationId" />
        </div>
      </v-dialog>

      <v-dialog
        v-model="showDeleteDialog"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        width="610px"
      >
        <v-card
          id="delete-delegate-dialog--container"
          v-ads-set-focus:delete-delegation-dialog--confirm-button
          v-ads-trap-focus
          aria-describedby="delete-delegation-dialog--description"
          aria-labelledby="delete-delegation-dialog--title"
          role="dialog"
        >
          <div class="pa-3 pt-6 pa-sm-6">
            <div class="d-flex align-start">
              <div class="mr-3">
                <CircleIcon icon="mdi-delete-outline" size="48" />
              </div>
              <div>
                <h3 id="delete-delegation-dialog--title">
                  Remove delegation
                </h3>
                <p id="delete-delegation-dialog--description">
                  Are you sure you want to immediately
                  <b>remove all delegation privileges</b> for this staff member?
                </p>
              </div>
              <v-spacer />
              <v-btn
                aria-label="close delete delegate dialog"
                icon
                @click="showDeleteDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div>
              <StaffDetailsCard
                :disable-verify-access="true"
                :staff="deleteStaff"
                class="ml-md-11 mt-3"
              />
            </div>
            <div
              v-if="deleteStaff != null"
              class="d-flex justify-end mr-3 pt-6"
            >
              <v-btn
                id="delete-delegation-dialog--confirm-button"
                :aria-label="
                  `Remove all delegation for ${deleteStaff.firstname} ${deleteStaff.lastname}`
                "
                class="white--text pl-6 pr-6"
                color="#ce0037"
                height="52px"
                @click="confirmDeleteDelegation"
              >
                <v-icon class="pr-3">
                  mdi-close
                </v-icon>
                Remove Delegation
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { InitialsIcon } from "@nswdoe/doe-ui-core";
import StaffDetailsCard from "@/components/StaffDetailsCard";
import CircleIcon from "@/components/CircleIcon";
import Pill from "@/components/Pill";
import { mapGetters } from "vuex";
import AddDelegateStaffList from "../components/AddDelegateStaffList";
import searchDebounce from "@/mixins/searchDebounce";
import colours from "@/settings/doe-colors";
import { UI_ACTIONS } from "@/utils/constants";

export default {
  components: {
    InitialsIcon,
    StaffDetailsCard,
    CircleIcon,
    AddDelegateStaffList,
    Pill
  },
  data() {
    return {
      searchString: this.$store.getters.delegatesFilter,
      showStaffSelect: false,
      isIE: document.documentMode != null,
      showDeleteDialog: false,
      deleteStaff: null
    };
  },
  computed: {
    ...mapGetters({
      delegates: "delegatesByLocation",
      isLoading: "isLoading",
      locationId: "selectedSchoolId",
      locType: "selectedLocType",
      locationName: "selectedSchoolName",
      fnActionByName: "getActionByName"
    }),
    uIActions: function() {
      return UI_ACTIONS;
    }
  },
  watch: {
    locationId: function(newSchool, oldSchool) {
      if (newSchool != null && newSchool !== oldSchool) {

        const locationType = this.$store.getters.locations.find(
                  l => l.id === newSchool
                  ).locType;
        //console.log("LocationType for the updated locationId is:" + locationType);
        this.$store.dispatch("fetchDelegatesForLocation", {
            locationId: newSchool,
            locType: locationType
            });
      }
    }
  },
  created() {
    if (this.locationId != null) {

      const locationType = this.$store.getters.locations.find(
                  l => l.id === this.locationId
                  ).locType;
      console.log("LocationType for the current locationId is:" + locationType);
      this.$store.dispatch("fetchDelegatesForLocation", {
        locationId: this.locationId,
        locType: this.locType
        });
    }
  },
  beforeDestroy() {
    this.$store.commit("setDelegatesFilter", "");
  },
  mixins: [searchDebounce],
  methods: {
    determinePillColour(accessLevel) {
      const al = (accessLevel === "Y" ? "full" : "partial").toUpperCase();
      const colorMap = {
        PARTIAL: { color: colours.white, background: colours.blue2 },
        FULL: { color: colours.white, background: colours.blue1 },
        PENDING: { color: colours.white, background: colours.warningOrange },
        default: { color: colours.blue1, background: colours.light20 }
      };
      if (accessLevel) {
        return colorMap[al];
      }
      return colorMap.default;
    },
    deleteDelegation(event, staff) {
      this.deleteStaff = staff;
      this.showDeleteDialog = true;
      event.stopPropagation();
    },
    confirmDeleteDelegation() {
      this.showDeleteDialog = false;
      const params = {
        locationId: this.locationId,
        locType: this.locType,
        locationName: this.locationName,
        detUserId: this.deleteStaff.detuserid,
        staffId: this.deleteStaff.netRegId,
        staffRole: this.deleteStaff.staffRole,
        applicationId: "1",
        applicationTaskId: "",
        enabledTasks: "",
        provision: false,
        provisionAll: false,
        revokeAll: true
      };
      this.$store.dispatch("delegateAllApps", params);
    },
    goToStaffAppDetails(staff) {
      if (this.fnActionByName(UI_ACTIONS.LIST_DELEGATE_APPS)) {
        this.$router.push({ path: "delegates/" + staff.detuserid });
      }
    },
    openStaffSelect() {
      this.showStaffSelect = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.add-person--button,
.add-person--button:hover {
  position: absolute;
  top: 8px;
  right: 0;
}

.add-person--button-ie-fix,
.add-person--button-ie-fix:hover {
  top: 10px;
}

::v-deep.mobile .v-select .v-input__control .v-input__slot .v-select__slot {
  padding-bottom: 1rem;
  padding-left: 1rem;
}

::v-deep div.v-dialog {
  background-color: #ffffff;
}

::v-deep div[role="listitem"] > a.v-list-item--highlighted {
  background-color: rgba(0, 0, 0, 0.15);
}

::v-deep div[role="listitem"] .v-list-item__mask {
  color: inherit;
  background-color: inherit;
  font-weight: 600;
}

.container {
  position: relative;
}

.btnRight {
  position: absolute;
  right: 20px;
}

.v-menu__content.v-autocomplete__content .v-card {
  -webkit-overflow-scrolling: touch;
}

.v-btn--bottom:not(.v-btn--absolute) {
  bottom: 90px;
}

::v-deep div.v-input__append-inner {
  margin-top: 10px;
  margin-bottom: 10px;
}

::v-deep .staff-select-dialog {
  min-height: 90%;
}

::v-deep {
  .v-list {
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .theme--dark {
    .v-list-item {
      border-bottom: 1px solid #6e6e6e;
    }
  }
}

.theme--dark {
  .data-table--header .v-list {
    background-color: #263238;
  }
}

.data-table--header .v-list {
  font-weight: 600;
  pointer-events: none;
  background-color: #cfd8dc;
}
</style>
